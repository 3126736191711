export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Fee Calculator"])},
  "textCalParking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter 4 digit pin below QR Code or Scan QR Code"])},
  "placeholderCalParking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter 4 digit pin code"])},
  "btnScanQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan QR Code"])},
  "btnCalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate"])},
  "calLabel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 digit QR Code pin"])},
  "calLabel2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
  "calLabel3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity ID/Passport No."])},
  "calLabel4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room No"])},
  "calLabel5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle"])},
  "calLabel6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License plate"])},
  "calLabel7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate by"])},
  "calLabel8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry Time"])},
  "calLabel9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current time"])},
  "calLabel10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking duration"])},
  "calLabel11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking fee"])},
  "calLabel12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "calLabel13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking fee total"])},
  "countDownText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the transaction within…"])},
  "sessionTimeOutHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time out"])},
  "sessionTimeOutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session has expired, please start a new transaction."])},
  "btnPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "parkingCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Terms and Conditions"])},
  "paymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment success"])},
  "paymentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please bring out a car from a parking spot and exit the building within 30 minutes, after the payment."])},
  "paymentDescriptionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ensure a smooth exit, kindly complete the following steps before leaving the premises."])},
  "paymentDescriptionLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Make the parking payment prior to exiting."])},
  "paymentDescriptionLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Present your parking ticket (QR Code) to the gate scanner for exit validation."])},
  "paymentDescriptionFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your cooperation."])},
  "paymentConfirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to confirm payment."])},
  "sessionExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expired"])},
  "sessionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This QR Code has been expired."])},
  "paymentAgain1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please press the "])},
  "paymentAgain2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["button and try again."])},
  "conditionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Conditions"])},
  "btnBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "paymentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This QR Code will expire in"])},
  "timeUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
  "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save QR Code"])},
  "descriptionPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan this QR Code for payment or press and hold the QR Code to save the image."])},
  "warningPayment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please bring out a car from a parking spot and scan"])},
  "warningPayment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR on receipt at security gate, after the payment."])},
  "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "btnCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Payment"])},
  "btnOk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment is time out. Please try again."])},
  "btnSaveInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Invoice"])},
  "btnbacktoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Homepage"])},
  "paymentMethodLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
  "penaltyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penalty"])},
  "stampTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Stamp"])},
  "stampMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking E-Stamp is available at commercial shops or you can proceed with the payment"])},
  "checkoutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Checkout"])},
  "checkoutMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have"])},
  "checkoutMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes to exit the building after check-out."])},
  "confirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed with the payment ?"])},
  "confirmTitleWithStamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Payment"])},
  "confirmTitleOverdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue Parking"])},
  "confirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can get your ticket stamped at the stores or designated parking ticket stamp desks."])},
  "confirmMessageWithStamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed with the payment ?"])},
  "confirmMessageOverdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with the payment of an overdue parking fee before exit the building."])},
  "btnPayNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now"])},
  "btnCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
  "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "btnPayLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Later"])},
  "scanMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place QR code within provided area."])},
  "msgTabAutomobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobile"])},
  "msgTabMotorcycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorcycle"])},
  "msgNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note:"])},
  "msgNote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost parking ticket will be fined."])},
  "msgNote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking rate & condition are subject to change without prior notice."])},
  "msgNote3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ticket can only be validated once subsequent validations are nulled."])},
  "msgNote4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The stamp rate shown is before the discount provided by the tenant who validates your tickets."])},
  "msgTabDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "msgTabUnstampedRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unstamped Rate"])},
  "msgTabStampedRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stamped Rate"])},
  "msgTabOfficeRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office"])},
  "msgTabRetailRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retail"])},
  "condition": {
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction:"])},
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusion:"])},
    "headerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitors Parking Terms and Conditions"])},
    "firstParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to P23's visitor parking facility. Please read these terms and conditions (\"Terms\") carefully. By using our parking facilities, you agree to be bound by these Terms."])},
    "lastParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your adherence to these Terms helps us maintain a safe and efficient parking environment. Thank you for choosing P23's parking facilities."])},
    "1": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance of Terms:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By entering, parking, or using our parking facilities, you agree to these Terms. If you do not agree to these Terms, please do not use the parking facilities."])}
    },
    "2": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use of Parking Facility:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The parking facility is available for use by visitors during the hours specified by P23, and no more than 24 hours parking is allowed."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles must be parked within the designated areas only and must not obstruct other vehicles or traffic flow."])}
    },
    "3": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Fees:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking fees are calculated based on the duration of parking, as indicated at the entrance and on our website."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment must be made via the designated payment methods before exiting the facility."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure to pay the parking fee may result in additional charges or legal action."])}
    },
    "4": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liability:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Lessor is not responsible for any loss, theft, or damage to vehicles or their contents while parked in the visitor parking area."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users park at their own risk."])}
    },
    "5": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While we endeavor to provide a secure parking environment, we cannot guarantee the security of your vehicle or its contents."])}
    },
    "6": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance with Rules:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All users must comply with all posted signs and parking regulations while using the facility."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-compliance may result in the vehicle being towed or clamped at the owner's expense."])}
    },
    "7": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Condition:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All vehicles parked in the facility must be roadworthy and have valid insurance, registration, and inspection as required by law."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Lessor reserves the right to refuse entry to any vehicle that does not meet these standards."])}
    },
    "8": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Restrictions:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Lessor reserves the right to restrict access to the parking facility at any time without notice."])}
    },
    "9": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications to Terms:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Lessor reserves the right to modify these Terms at any time. The most current version will be available via the QR code link."])}
    },
    "10": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data collected during the parking process will be handled in accordance with our privacy policy, available on our website."])}
    },
    "11": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governing Law:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which the parking facility is located."])}
    },
    "12": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For any queries or assistance, please contact  Line : ", "@", "p23official"])}
    },
    "13": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination of Use:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Lessor reserves the right to terminate any user's access to the parking facilities for breach of these Terms."])}
    },
    "14": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indemnification:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users agree to indemnify and hold harmless the Lessor from any claims, damages, losses, liabilities, and expenses arising out of or in connection with the violation of these Terms or the use of the parking facility."])}
    },
    "15": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Situations:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the event of an emergency, users should follow the instructions of security personnel and posted emergency procedures."])}
    },
    "16": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Lessor makes no warranties, express or implied, regarding the parking facility, including its fitness for any particular purpose."])}
    }
  }
}