import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { selectedLocale } from '@/plugins/i18n'

export default createStore({
  state: {
    locale: selectedLocale
  },
  mutations: {
    updateLocale(state, newLocale) {
      state.locale = newLocale
    }
  },
  actions: {
    changeLocale({ commit }, newLocale) {
      commit('updateLocale', newLocale)
    }
  },
  plugins: [createPersistedState()]
})
