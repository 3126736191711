import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'calparking',
    component: () => import(/* webpackChunkName: "calparking" */ '../views/CalParking.vue'),
    props: true
  },
  {
    path: '/scanner',
    name: 'qrscanner',
    component: () => import(/* webpackChunkName: "qrscanner" */ '../views/QRcodeScanner.vue'),
    props: true
  },
  {
    path: '/payment',
    name: 'paymentparking',
    component: () => import(/* webpackChunkName: "paymentparking" */ '../views/QRcodePayment.vue'),
    props: true
  },
  {
    path: '/invoice',
    name: 'taxinvoice',
    component: () => import(/* webpackChunkName: "taxinvoice" */ '../views/TaxInvoice.vue'),
    props: true
  },
  {
    path: '/condition',
    name: 'parkingcondition',
    component: () => import(/* webpackChunkName: "parkingcondition" */ '../views/ParkingCondition.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
