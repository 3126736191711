export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำนวณค่าที่จอดรถ"])},
  "textCalParking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกเลขใต้ QR Code 4 หลัก หรือสแกน QR Code"])},
  "placeholderCalParking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกเลขใต้ QR Code 4 หลัก"])},
  "btnScanQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สแกน QR Code"])},
  "btnCalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำนวณ"])},
  "calLabel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัส QR Code 4 หลัก"])},
  "calLabel2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ-นามสกุล"])},
  "calLabel3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลขบัตรประชาชน / เลขที่หนังสือเดินทาง"])},
  "calLabel4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขห้อง"])},
  "calLabel5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยานพาหนะ"])},
  "calLabel6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้ายทะเบียน"])},
  "calLabel7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตราประทับ"])},
  "calLabel8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาเข้า"])},
  "calLabel9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาออก"])},
  "calLabel10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาการจอด"])},
  "calLabel11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าจอดสุทธิ"])},
  "calLabel12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนลด"])},
  "calLabel13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าที่จอดรถทั้งหมด"])},
  "countDownText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาทำรายการภายใน..."])},
  "sessionTimeOutHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เซสชันหมดอายุ"])},
  "sessionTimeOutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาทำรายการใหม่"])},
  "btnPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระเงิน"])},
  "parkingCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อกำหนดและเงื่อนไขการจอดรถ"])},
  "paymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระเงินสำเร็จ"])},
  "paymentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณมีเวลา 30 นาทีในการออกจากอาคารหลังจากชำระเงิน"])},
  "paymentDescriptionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อให้แน่ใจว่าการออกจากสถานที่เป็นไปได้อย่างราบรื่น กรุณาทำตามขั้นตอนต่อไปนี้"])},
  "paymentDescriptionLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- ชำระค่าจอดรถก่อนออก"])},
  "paymentDescriptionLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- แสดงบัตรจอดรถ (QR Code) ที่เครื่องสแกนตรงด่านออก"])},
  "paymentDescriptionFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอบคุณสำหรับความร่วมมือของคุณ"])},
  "paymentConfirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิกที่นี่ เพื่อยืนยันการชำระเงิน"])},
  "sessionExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session หมดอายุ"])},
  "sessionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดำเนินการของ QR Code นี้หมดอายุแล้ว"])},
  "paymentAgain1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากดปุ่ม "])},
  "paymentAgain2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" เพื่อใหม่ดำเนินการอีกครั้ง"])},
  "conditionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อกำหนดและเงื่อนไข"])},
  "btnBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้อนกลับ"])},
  "paymentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code นี้จะหมดอายุภายใน"])},
  "timeUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นาที"])},
  "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก QR Code"])},
  "descriptionPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สแกน QR Code นี้เพื่อชำระเงิน หรือกดค้างที่ QR Code เพื่อบันทึกรูปภาพ"])},
  "warningPayment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณานำรถออกจากที่จอดรถ และนำ QR Code บนใบเสร็จ"])},
  "warningPayment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไปสแกนที่ประตูรักษาความปลอดภัยขาออกหลังจากชำระเงิน"])},
  "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
  "btnCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบการชำระเงิน"])},
  "btnOk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตกลง"])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อผิดพลาด"])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดำเนินการชำระเงินหมดเวลาแล้ว กรุณาลองใหม่อีกครั้ง"])},
  "btnSaveInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกใบเสร็จ"])},
  "btnbacktoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับสู่หน้าหลัก"])},
  "paymentMethodLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีการชำระเงิน"])},
  "penaltyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าปรับ"])},
  "stampTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สแตมป์ที่จอดรถ"])},
  "stampMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อรักษาสิทธิ์ของคุณ คุณสามารถแสตมป์ที่จอดรถกับทางร้านค้าได้ หรือในกรณีที่ไม่แสตมป์กับร้านค้า คุณสามารถเลือกชำระเงินได้ทันที"])},
  "btnPayNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระเงินทันที"])},
  "btnCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เช็คเอาท์"])},
  "checkoutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันการเช็คเอาท์"])},
  "checkoutMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณมีเวลา"])},
  "checkoutMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นาทีในการออกจากอาคารหลังจากเช็คเอาท์"])},
  "confirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต้องการดำเนินการชำระเงิน ?"])},
  "confirmTitleWithStamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันการชำระเงิน"])},
  "confirmTitleOverdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จอดรถเกินเวลา"])},
  "confirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถนำบัตรจอดรถมาประทับตรา (E-Stamp) ได้ที่ร้านค้าหรือจุดที่รับบริการ"])},
  "confirmMessageWithStamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต้องการดำเนินการชำระเงิน ?"])},
  "confirmMessageOverdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาชำระเงินค่าจอดเกินเวลาก่อนนำรถออกจากอาคาร"])},
  "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยัน"])},
  "btnPayLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่ชำระเงิน"])},
  "scanMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาวาง QR Code ในตำแหน่งที่กำหนด"])},
  "msgTabAutomobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รถยนต์"])},
  "msgTabMotorcycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอเตอร์ไซต์"])},
  "msgNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเหตุ:"])},
  "msgNote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรณีทำบัตรจอดรถหายจะต้องเสียค่าปรับ"])},
  "msgNote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัตราและเงื่อนไขค่าที่จอดรถอาจเปลี่ยนแปลงได้ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า"])},
  "msgNote3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัตรจอดรถสามารถประทับตราได้เพียงครั้งเดียวและการประทับตราซ้ำหลังจากนั้นจะไม่มีผล"])},
  "msgNote4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรทการประทับตราที่แสดงจะเป็นเรทก่อนได้รับส่วนลดจากผู้เช่าที่ประทับตราบัตรจอดรถของคุณ"])},
  "msgTabDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลา"])},
  "msgTabUnstampedRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ประทับตรา"])},
  "msgTabStampedRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประทับตรา"])},
  "msgTabOfficeRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บริษัท"])},
  "msgTabRetailRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ร้านค้า"])},
  "condition": {
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บทนำ:"])},
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สรุป:"])},
    "headerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อกำหนดและเงื่อนไขสำหรับที่จอดรถของผู้มาติดต่อ"])},
    "firstParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยินดีต้อนรับสู่พื้นที่จอดรถสำหรับผู้มาติดต่ออาคาร P23 โปรดอ่านข้อกำหนดและเงื่อนไขเหล่านี้อย่างละเอียด (“ข้อกำหนด”) ซึ่งท่านยอมรับที่จะผูกพันตามข้อกำหนดเหล่านี้สำหรับการเข้าใช้พื้นที่จอดรถอาคาร P23"])},
    "lastParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปฏิบัติตามข้อกำหนดฉบับนี้ จะช่วยให้การบริการพื้นที่จอดรถเป็นไปอย่างปลอดภัยและมีประสิทธิภาพ ขอบคุณที่เลือกใช้บริการพื้นที่จอดรถอาคาร P23"])},
    "1": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยอมรับข้อกำหนด:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดยการเข้าจอดหรือใช้พื้นที่จอดรถอาคาร P23 ขอให้ท่านยอมรับข้อกำหนดฉบับนี้ หากท่านไม่ยอมรับข้อกำหนดฉบับนี้ กรุณางดการใช้พื้นที่ของอาคารจอดรถ P23"])}
    },
    "2": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การใช้พื้นที่จอดรถ:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พื้นที่จอดรถสามารถใช้ได้สำหรับผู้มาติดต่อในช่วงเวลาที่ระบุไว้ โดยมิให้จอดเกิน 24 ชั่วโมง"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รถยนต์ต้องจอดในพื้นที่ที่กำหนดเท่านั้น และต้องไม่กีดขวางรถยนต์คันอื่นหรือการจราจร"])}
    },
    "3": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าธรรมเนียมจอดรถ:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าธรรมเนียมจอดรถคำนวณตามระยะเวลาของการจอด ตามที่ระบุไว้"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าธรรมเนียมต้องชำระผ่านวิธีการชำระเงินที่กำหนดก่อนออกจากพื้นที่จอดรถ"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากไม่ชำระค่าธรรมเนียมจอดรถอาจทำให้เกิดค่าใช้จ่ายเพิ่มเติมหรือการดำเนินการทางกฎหมาย"])}
    },
    "4": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความรับผิดชอบ:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทางอาคาร P23 ไม่รับผิดชอบต่อการสูญเสีย การโจรกรรม หรือความเสียหายของรถยนต์หรือสิ่งของในรถขณะจอดในพื้นที่จอดรถของผู้มาติดต่อ"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้บริการจอดรถตกลงเป็นผู้รับความเสี่ยงในทุกเรื่องเองทั้งหมด"])}
    },
    "5": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความปลอดภัย:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แม้ว่าอาคารจะพยายามให้บริการจอดรถที่ปลอดภัย แต่อาคารไม่สามารถรับประกันความปลอดภัยของรถยนต์หรือสิ่งของในรถของท่านได้"])}
    },
    "6": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปฏิบัติตามกฎ:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้ทุกคนต้องปฏิบัติตามป้ายที่ติดตั้งและกฎการจอดรถขณะใช้บริการในพื้นที่จอดรถอาคาร P23"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การไม่ปฏิบัติตามอาจทำให้รถถูกยกหรือถูกล็อคล้อ"])}
    },
    "7": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สภาพของรถยนต์:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รถยนต์ที่จอดในพื้นที่จอดรถต้องมีสภาพที่สามารถใช้งานได้จริง มีประกันภัย มีการจดทะเบียน และผ่านการตรวจสภาพตามที่กฎหมายกำหนด"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทางอาคาร P23 สงวนสิทธิ์ที่จะปฏิเสธการเข้าใช้บริการของรถยนต์ที่ไม่ตรงตามมาตรฐานเหล่านี้"])}
    },
    "8": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อจำกัดในการอนุญาตเข้าใช้:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทางอาคาร P23 สงวนสิทธิ์ที่จะจำกัดการเข้าใช้พื้นที่จอดรถได้ทุกเมื่อโดยไม่ต้องแจ้งล่วงหน้า"])}
    },
    "9": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การแก้ไขข้อกำหนด:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทางอาคาร P23 สงวนสิทธิ์ในการแก้ไขข้อกำหนดได้ทุกเมื่อ โดยยึดถือข้อกำหนดฉบับล่าสุด และจะมีให้บริการผ่านลิงค์ QR code"])}
    },
    "10": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นโยบายความเป็นส่วนตัว:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลส่วนบุคคลที่รวบรวมระหว่างเข้าใช้บริการพื้นที่จอดรถจะได้รับการจัดเก็บตามนโยบายความเป็นส่วนตัวของอาคาร"])}
    },
    "11": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กฎหมายที่ใช้บังคับ:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อกำหนดเหล่านี้จะถูกควบคุมและตีความตามกฎหมายของศาลประเทศไทยของเขตพื้นที่ที่อาคารจอดรถ P23 ตั้งอยู่"])}
    },
    "12": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลติดต่อ:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สำหรับคำถามหรือความช่วยเหลือใด ๆ กรุณาติดต่อที่  Line : ", "@", "p23official"])}
    },
    "13": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การยกเลิกการใช้บริการ:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทางอาคาร P23 สงวนสิทธิ์ในการยกเลิกการเข้าใช้บริการพื้นที่จอดรถ สำหรับผู้ใช้บริการที่ละเมิดข้อกำหนดฉบับนี้"])}
    },
    "14": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปกป้องความเสียหาย:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ใช้บริการตกลงที่จะปกป้องและไม่ทำให้ทางอาคาร P23 เกิดความเสียหายจากการเรียกร้องใดๆ หรือจากหนี้สิน หรือค่าใช้จ่ายที่เกิดขึ้นจากการละเมิดข้อกำหนดฉบับนี้หรือการใช้พื้นที่จอดรถอาคาร"])}
    },
    "15": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานการณ์ฉุกเฉิน:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในกรณีฉุกเฉิน ผู้ใช้บริการควรปฏิบัติตามคำแนะนำของเจ้าหน้าที่รักษาความปลอดภัยและขั้นตอนฉุกเฉินที่ประกาศไว้"])}
    },
    "16": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำชี้แจงปฏิเสธความรับผิด:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ให้เช่าไม่ได้ให้คำรับประกันใด ๆ แบบโดยตรงหรือไม่ตรงเกี่ยวกับสถานที่จอดรถ รวมทั้งความเหมาะสมสำหรับวัตถุประสงค์ใด ๆ อย่างเฉพาะเจาะจง"])}
    }
  }
}