<template>
  <footer class="footer-custom align-middle"> 
    <div class="image-box">
      <img
        class="logo-img"
        src="../assets/logo_footer.png"
        alt="Letmein LOGO"
      >
    </div>
    <div class="text-box">
      <img
        class="line-logo"
        src="../assets/line_logo.png"
        alt="Letmein LOGO"
      >
      <span class="line-text">@letmein.care</span>
    </div>
  </footer>
</template>

<script>
export default {
name: 'FooterComponents'
}
</script>

<style>
.footer-custom {
    display: flex;
    justify-content: space-between;
    background-color: #3f4855;
    padding: 15px 25px;
    height: 60px;
}

.footer-custom .image-box {
  width: 140px;
  height: 30px;
}

.footer-custom .logo-img {
  width: 100%;
  height: 100%;
}

.footer-custom .line-logo {
  width: auto;
  height: 100%;
}

.footer-custom .text-box {
  padding: 2px 0px;
}

.footer-custom .line-text {
  margin-left: 4px;
  color: white;
  font-size: 14px;
}
</style>